body {
  background-color: #edeff1;

  margin: 0;
  font-family: "Spinnaker", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
  height: var(--app-height);
  background-color: #fbfbfb;
}

.ui.modal {
  top: 20% !important;
}
