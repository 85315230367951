.headerContainer {
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #038eca;

  justify-content: space-between;
  width: 100%;
}

.headerText {
  font-variant: all-small-caps;
  font-size: 4vh;
}

.headerButton {
  background-color: #038eca !important;
  color: white !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 10% !important;
  display: flex !important;
  justify-content: center !important;
  letter-spacing: 0.2rem !important;
  align-items: center;
  font-size: 20px !important;
}

.headerSubtext {
  font-size: 2vh;
  color: #b1b3b4;
  font-variant: all-small-caps;
  margin-bottom: 16px;
}
