.postGameModalContainer {
  font-variant: all-small-caps;
  padding: 24px;

  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#postGameModalContainer {
  display: flex !important;
}

.pgHeader {
  text-align: center;
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: bold;
}

.pgScore {
  font-size: 24px;
  margin-bottom: 12px;
}

.pgRow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-bottom: 12px;
}

.shareButton {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
  font-size: 40px !important;
  padding: 12px 36px !important;
  padding-top: 6px !important;
  margin-right: 0px !important;
}
