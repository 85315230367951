.helpModalContainer {
  font-variant: all-small-caps;
  padding: 24px;

  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: "Spinnaker", sans-serif;
}

#helpModalContainer {
  display: flex !important;
  flex-direction: column !important;
  font-size: 20px;
  align-items: flex-start;
}

#helpModalContainer div {
  margin-bottom: 24px;
}

.helpModalTitle {
  margin: auto;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: bold;
}
