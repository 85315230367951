.gameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 100%;
  padding: 0 12px;

  margin-top: 10%;
  max-height: 800px;
}

.boardRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tile {
  align-items: center;
  background-color: #038eca;

  /* border: 1px solid #00a6ed; */
  cursor: pointer;
  display: flex;
  font-size: xx-large;
  /* height: min(80px, 15vw); */
  justify-content: center;
  /* width: min(80px, 15vw); */
  flex-grow: 1;

  border-radius: 10px;
  margin: 2px;

  /* border-top: unset; */
  /* border-right: unset; */
}

.tile--first-row {
  /* border-top: 1px solid #00a6ed; */
}

.tile:nth-of-type(5) {
  /* border-right: 1px solid #00a6ed; */
}

.tile--selected {
  background-color: #7dc5e5;
}

.tile--missed {
  background-color: #fca5a5;
}

.tile--hit {
  background-color: #86efac;
}

.fireAwayButton {
  margin-top: 16px !important;
  font-variant: all-small-caps;
  /* font-size: x-large !important; */
}
